<template>
  <section class="outContainer">
      <!-- 筛选条件栏 -->
      <div class="search">
        <el-input class="each" v-model="searchInfo.param.name" placeholder="车主姓名">
        </el-input>
        <el-input class="each" v-model="searchInfo.param.phone" placeholder="联系电话">
        </el-input>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="hanldleSearch('search')">搜索</el-button>
        <el-button size="mini" type="primary" icon="el-icon-close" @click="hanldleSearch('clear')">清空搜索条件</el-button>
      </div>
      <!-- 表格 -->
      <div class="tableContainer">
        <el-table :data="tableList" height="100%" style="width: 100%">
          <el-table-column align="center" label="用户信息" prop="wechatName">
            <template slot-scope="scope">
              <p>{{ scope.row.name ? scope.row.name : '--' }}</p>
              <p>{{ scope.row.phone ? scope.row.phone : '--' }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="建议内容" prop="opinionContent">
          </el-table-column>
          <el-table-column align="center" label="图片" prop="imageList">
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.imageList && scope.row.imageList.length > 0"
                style="width: 100px; height: 100px"
                :src="scope.row.imageList[0]"
                title="可点击放大查看"
                :preview-src-list="scope.row.imageList">
              </el-image>
              <p v-else>--</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="创建时间" prop="createTimeStr">
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <pagination v-bind:child-msg="searchInfo" @callFather="callFather"></pagination>
  </section>
</template>

<script>
import pagination from '@/components/Pagination.vue';
import { getFeedbackList } from '@/api/public.js';
import { timeChange } from '@/api/publicFun.js';
export default {
  data() {
    return {
        tableList:[], // 表格数据
        alt:"点击放大",
        searchInfo:{ // 搜索条件
            pageNo:1,
            pageSize:10,
            total:0,
            param:{
                name:"",
                phone:"",
            }
        }
    }
  },

  components: {
    pagination
  },

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 筛选事件
    hanldleSearch(type){
        this.searchInfo.pageNo = 1;
        if(type == 'clear'){
            this.searchInfo.param = {};
        }else{
            this.searchInfo.param.name = this.searchInfo.param.name == "" ? null : this.searchInfo.param.name;
            this.searchInfo.param.phone = this.searchInfo.param.phone == "" ? null : this.searchInfo.param.phone;
        }
        this.init();
    },
    // 获取表格数据
    init(){
      getFeedbackList(this.searchInfo).then(res=>{
        if(res.success){
          this.tableList = res.data.list;
          this.searchInfo.total = res.data.total;
          this.tableList.forEach(element => {
            let eachTime = timeChange(element.createTime);
            element.createTimeStr = `${eachTime.year}-${eachTime.month}-${eachTime.day} ${eachTime.hour}:${eachTime.minutes}:${eachTime.seconds}`;
          });
        }else{
          this.tableList = [];
          this.searchInfo.total = 0;
        }
      })
    },
    // 分页插件事件
    callFather(parm) {
        this.searchInfo.pageNo = parm.currentPage;
        this.init();
    },
  },
};
</script>
<style scoped></style>
